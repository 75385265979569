nav.main-navigation {
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 20;
  height: 7vh;
  width: 100%;
  text-align: right;
  font-size: 0px;
}

.main-navigation div.shadow {
  display: inline-block;
  margin-right: 3vw;
  width: 35%;
  box-shadow: 0 0 10vh 2vh white;
}

.main-navigation ul {
  height: 100%;
  width: 100%;
  list-style: none;
  text-align: right;
}

.main-navigation ul li {
  display: inline-block;
  height: 100%;
  font-size: 3.15vh;
  color: block;
  margin-right: 5vw;
}

.main-navigation ul li a {
  text-decoration: none;
  color: black;
  transition: 0.3s;
}

.main-navigation ul li a:hover {
  cursor: pointer;
}

.main-navigation ul li a.active {
  border-bottom: white solid 0.3vh;
}

@media (max-width: 420px) and (orientation: portrait) {
  nav.main-navigation {
    height: 5vh;
    top: 8px;
  }
  .main-navigation ul li {
    font-size: 20px;
  }

  .main-navigation div.shadow {
    margin-right: 3vw;
    width: 70%;
  }
}

@media (max-width: 376px) and (orientation: portrait) {
  nav.main-navigation {
    height: 5vh;
    top: 8px;
  }
  .main-navigation ul li {
    font-size: 18px;
  }
}

@media (max-width: 360px) and (orientation: portrait) {
  nav.main-navigation {
    height: 5vh;
    top: 10px;
  }
  .main-navigation ul li {
    font-size: 16px;
  }
  .main-navigation ul li {
    margin-right: 2vw;
    margin-left: 3vw;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  nav.main-navigation {
    height: 5vh;
    top: 8px;
  }
  .main-navigation ul li {
    font-size: 20px;
    margin-left: 2vw;
  }
}
