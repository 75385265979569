@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400,600,700&display=swap&subset=latin-ext");

/* preloading files */

body:after {
  /* zobaczyc czy tak zadziala */
  content: url(../img/game1off.gif) url(../img/game1on.gif)
    url(../img/game2off.gif) url(../img/game2on.gif) url(../img/game3off.gif)
    url(../img/game3on.gif) url(../img/bgc1.png) url(../img/bgc2.png)
    url(../img/bgc3.png) url(../img/bgc4.png) url(../img/bgc5.png)
    url(../img/gif1.gif) url(../img/gif2.gif) url(../img/examplePhoto.jpg)
    url(../img/flagES.png) url(../img/flagUS.png) url(../img/map.png)
    url(../img/galaxy.jpg);
  display: none;
}

* {
  margin: 0;
  padding: 0;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

body {
  /* min-height: 1000vh; */
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  font-family: "Barlow Condensed", sans-serif;
}

.stop-scrolling {
  height: 100vh;
  overflow: hidden;
}

button.language {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 2.75vh;
  width: 3.75vh;
  background-size: 3vh 2vh;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0), 0 0 0 2px lightgrey;
  border-radius: 5px;
  transition: 0.2s;
  z-index: 999;
}

button.language:hover {
  cursor: pointer;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0), 0 0 0 3px white;
}

button.language:focus {
  outline: none;
}

button.language:nth-of-type(2) {
  left: 6.5vh;
}

button.US {
  background-image: url(../img/flagUS.png);
}
button.ES {
  background-image: url(../img/flagES.png);
}

@media (max-width: 900px) and (orientation: landscape) {
  button.language {
    position: absolute;
    height: 4vh;
    width: 6vh;
    background-size: 5vh 3.5vh;
    border-radius: 4px;
  }

  button.language:nth-of-type(2) {
    left: 11vh;
  }
}
