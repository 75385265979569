.project-section{
    min-height: 80vh;
    background-color: #0c1015;
    position: relative;
    z-index: 2;
    color: white;
    width: 100vw;
    padding: 0 2vmin;
}

.project-section .project {
    display: flex;
    flex-direction: row;
    padding: 5vh 0;
    width: 100%;
}

.project-section .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vh;
    opacity: 0;
}

@keyframes opacityTransition {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.project-section .left.active {
    animation: opacityTransition 1s linear;
    animation-fill-mode: both;
}

.project-section .left a {
    color: skyblue;
}

.project-section .right {
    flex-grow: 1;
    padding: 0 1vw;
    max-width: 70%;
}

.project-section .photos {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 3vh;
    width: 100%;
    opacity: 0;
    transform: translateX(50%);
}

@keyframes showLeft {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.project-section .photos.active {
    animation: showLeft 0.3s linear;
    animation-fill-mode: both;
}

.project-section .photo {
    /* flex-basis: 33.3%; */
    height: 30vh;
    background-size: cover;
    background-position: center;
    /* padding: 0 1vw; */
    border-radius: 20px;
}

.project-section img.logo {
    width: 25vw;
    padding: 0 2.5vw;
}

.project-section h3{
    font-size: 4rem;
    margin-bottom: 1vh;
}

.project-section ul{
    padding: 0 5vmin;
}

@media (max-width: 1100px) and (orientation: portrait) {
    .project-section .left {
        display: none;
    }

    .project-section .right {
        max-width: 100%;
    }

    .project-section h3{
        font-size: 3rem;
        margin-bottom: 3vh;
    }
}
  
@media (max-width: 420px) and (orientation: portrait) {

}
  
@media (max-width: 400px) and (orientation: portrait) {
  
}
  
@media (max-width: 350px) and (orientation: portrait) {
    
}
  
@media (max-width: 1200px) and (orientation: landscape) {
  
}
  
@media (max-width: 900px) and (orientation: landscape) {
  
}

@media (max-width: 800px) and (orientation: landscape) {
  
}

@media (max-width: 700px) and (orientation: landscape) {
   
}