div.achievement {
    position: relative;
    background-color: #141714;
    /* background-color: #161b22; */
    color: #000;
    padding: 10px;
    border: #31422a solid 2px;
    /* border: #30363d solid 2px; */
    border-radius: 5px;
    transform: rotateX(90deg);
    transition: 0.4s;
    cursor: default;
    overflow: hidden;
    z-index: 3;
    opacity: 0;
    flex-basis: 45%;

    display: flex;
    flex-direction: row;
}

div.achievement:nth-child(2n) {
    margin-left: 2vmax;
    margin-top: 10vmax;
    align-self: flex-end;
}

div.achievement:nth-child(2n-1) {
    margin-right: 2vmax;
    margin-bottom: 10vmax;
    align-self: flex-start;
}

div.achievement.active {
    animation: showUp 0.3s linear;
    animation-fill-mode: both;
}

div.achievement:last-child {
    margin-bottom: 0vh;
    filter: opacity(0);
}

.achievement .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-basis: auto;
}

.achievement .left {
    color: #818891;
}

.achievement .right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.achievement img {
    width: 7.5vmax;
    margin-right: 5px;
}

.achievement div.right {
    color: white;
    padding: 0 5px;
}

.achievement .title {
    font-weight: 500;
    font-size: 1.2em;
    /* height: 5vh; */
    /* line-height: 5vh; */
    color: #fefefe;
}

.achievement .description {
    font-size: 1em;
    font-weight: 300;
    text-align: justify;
    color: #818891;
}

.shine {
    width: 30vmax;
    height: 30vmax;
    background-color: #ffa28b;
    filter: blur(10vmax);
    border-radius: 300px;
    opacity: 0.85;
    transition: opacity 1.2s cubic-bezier(0.16, 1, 0.3, 1);
    mix-blend-mode: soft-light;
    will-change: transform;
    pointer-events: none;
    /* z-index: -1; */
    top: 50%;
    left: 50%;
    position: absolute;
}

@keyframes showUp {
    0% {
        opacity: 0;
        top: 20vmin;
    }
    100% {
        opacity: 1;
        top: 0vmin;
    }
}


/* MOBILES */


@media (max-width: 1100px) and (orientation: portrait) {
    div.achievement:nth-child(2n) {
        margin: 1vmax 0;
    }
    
    div.achievement:nth-child(2n-1) {
        margin: 1vmax 0;
    }
   
    div.achievement:first-child {
        margin-top: 5vmax;
    }
   
    div.achievement:last-child {
        margin-bottom: 3vmax;
    }
}

@media (max-width: 900px) and (orientation: portrait) {

}

@media (max-width: 600px) and (orientation: portrait) {

}

@media (max-width: 400px) and (orientation: portrait) {

}

@media (max-width: 361px) and (orientation: portrait) {

}

@media (max-width: 359px) and (orientation: portrait) {

}

@media (max-width: 1400px) and (orientation: landscape) {

}

@media (max-width: 1400px) and (min-height: 900px) and (orientation: landscape) {

}

@media (max-width: 1200px) and (orientation: landscape) {

}

@media (max-width: 900px) and (orientation: landscape) {

}

@media (max-width: 800px) and (orientation: landscape) {

}

@media (max-width: 700px) and (orientation: landscape) {

}

@media (max-width: 600px) and (orientation: landscape) {

}