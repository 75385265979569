  section.bio {
    position: relative;
    height: 80vh;
    background-color: #0c1015;
    z-index: 0;
    padding-top: 10vh;
  
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  
  div.profile-photo { 
    height: 25vmax;
    width: 25vmax;
    box-shadow: 0 0 0 1vh #0c1015, 0 0 0 2vh #07700d;
    border-radius: 30vh;
    background-image: url(../../../img/profile_pic_3.jpeg);
    background-size: cover;
    z-index: 12;
    transform: rotateY(90deg);
    transition: 1s;
    filter: brightness(90%);
    margin-left: 5vmax;
  }
  
  div.active-profile-photo {
    transform: rotateY(0deg);
    animation: profilePhotoAnimation 1s ease 1.1s 1;
  }
  
  @keyframes profilePhotoAnimation {
    0% {
      box-shadow: 0 0 0 1vh #0c1015, 0 0 0 2vh #07700d;
    }
    50% {
      box-shadow: 0 0 0 3vh #0c1015, 0 0 0 5vh #07700d;
    }
    100% {
      box-shadow: 0 0 0 1vh #0c1015, 0 0 0 2vh #07700d;
    }
  }
  
  div.description {
    position: relative;
    flex-basis: 40%;
    font-size: 1.3em;
    line-height: 1.4;
    color: white;
  }
  
  section.bio div.description p {
    display: inline;
  }
  
  span.cursor {
    display: inline;
    opacity: 0;
    animation: cursorAnimation 0.8s infinite linear;
  }
  
  @keyframes cursorAnimation {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* border */
  
  div.border {
    position: absolute;
    top: 2%;
    left: 2.5%;
    height: 500vh;
    width: 95%;
    border: lime solid 2px;
    border-bottom: none;
    border-radius: 4px;
    z-index: 2;
    opacity: 0.2;
  }
  
  div.border div.initial {
    position: absolute;
    width: 2px;
    height: 10%;
    top: -15%;
    background-color: lime;
    left: 50%;
    opacity: 0;
    transition: 1s;
  }
  
  div.border div.top1 {
    position: absolute;
    width: 50%;
    height: 10px;
    top: -5px;
    left: 0;
    background-color: #0c1015;
    transition: 2s;
    transition-delay: 1s;
  }

  div.border div.top2 {
    position: absolute;
    width: 50%;
    height: 10px;
    top: -5px;
    left: 50%;
    background-color: #0c1015;
    transition: 2s;
    transition-delay: 1s;
  }

  div.border div.left {
    position: absolute;
    width: 10px;
    height: 100%;
    top: -5px;
    left: -5px;
    background-color: #0c1015;
    transition: top 3s 1.5s, opacity 0s 3.5s;
  }

  div.border div.right {
    position: absolute;
    width: 10px;
    height: 100%;
    top: -5px;
    right: -5px;
    background-color: #0c1015;
    transition: top 3s 1.5s, opacity 0s 3.5s;
  }
  
  div.border div.top1.active {
    left: -100%;
  }
  
  div.border div.top2.active {
    left: 150%;
  }
  
  div.border div.left.active,
  div.border div.right.active {
    top: 105%;
    opacity: 0;
  }
  
  div.border div.initial.active {
    top: -10%;
    opacity: 1;
  }
  
  /* MOBILES */
  
  @media (max-width: 1100px) and (orientation: portrait) {
    section.bio {
      flex-direction: column;
      height: auto;
    }

    div.profile-photo {
      height: 600px;
      width: 600px;
      box-shadow: 0 0 0 12px #0c1015, 0 0 0 24px #07700d;
      border-radius: 300px;
      margin: 5vmax 0 10vmax;
    }

    div.description {
      width: 70%;
      font-size: 2rem;
      line-height: 1.4;
    }
  
    @keyframes profilePhotoAnimation {
      0% {
        box-shadow: 0 0 0 12px #0c1015, 0 0 0 24px #07700d;
      }
      50% {
        box-shadow: 0 0 0 30px #0c1015, 0 0 0 50px #07700d;
      }
      100% {
        box-shadow: 0 0 0 12px #0c1015, 0 0 0 24px #07700d;
      }
    }
  }
  
  @media (max-width: 900px) and (orientation: portrait) {
    div.profile-photo {
      height: 400px;
      width: 400px;
      box-shadow: 0 0 0 12px #0c1015, 0 0 0 24px #07700d;
      border-radius: 200px;
      background-size: cover;
    }
  
    @keyframes profilePhotoAnimation {
      0% {
        box-shadow: 0 0 0 12px #0c1015, 0 0 0 24px #07700d;
      }
      50% {
        box-shadow: 0 0 0 30px #0c1015, 0 0 0 50px #07700d;
      }
      100% {
        box-shadow: 0 0 0 12px #0c1015, 0 0 0 24px #07700d;
      }
    }
  
    
  }

  @media (max-width: 600px) and (orientation: portrait) {
    div.profile-photo {
      height: 200px;
      width: 200px;
      box-shadow: 0 0 0 7px #0c1015, 0 0 0 14px #07700d;
      border-radius: 125px;
      background-size: cover;
    }
  
    @keyframes profilePhotoAnimation {
      0% {
        box-shadow: 0 0 0 7px #0c1015, 0 0 0 14px #07700d;
      }
      50% {
        box-shadow: 0 0 0 18px #0c1015, 0 0 0 32px #07700d;
      }
      100% {
        box-shadow: 0 0 0 7px #0c1015, 0 0 0 14px #07700d;
      }
    }
  
    div.border {
      display: none;
    }
    
  }
  
  @media (max-width: 400px) and (orientation: portrait) {
    div.description {
      width: 80%;
      font-size: 19px;
    }
  }
  
  @media (max-width: 361px) and (orientation: portrait) {
    div.description {
      font-size: 18px;
    }
  }

  @media (max-width: 359px) and (orientation: portrait) {
    div.profile-photo {
      height: 220px;
      width: 220px;
    }
  
    div.description {
      transform: translate(-50%, 0);
      width: 70%;
      text-align: center;
      font-size: 16px;
      line-height: 1.4;
    }
  }
  
  @media (max-width: 1400px) and (orientation: landscape) {
    section.bio {
      position: relative;
      height: 100vh;
      width: 100%;
    }
  
    div.profile-photo {
      height: 450px;
      width: 450px;
      border-radius: 225px;
    }
  }
  
  @media (max-width: 1400px) and (min-height: 900px) and (orientation: landscape) {
    section.bio {
      position: relative;
      height: 70vh;
      width: 100%;
    }
  }
  
  @media (max-width: 1200px) and (orientation: landscape) {
    section.bio {
      position: relative;
      height: 70vh;
      width: 100%;
    }
  
    div.profile-photo {
      height: 350px;
      width: 350px;
      border-radius: 225px;
    }
  
    div.description {
      width: 45%;
      font-size: 25px;
      line-height: 1.4;
    }
  }
  
  @media (max-width: 900px) and (orientation: landscape) {
    :root {
      --cube-size: 30vh;
    }
  
    section.bio {
      position: relative;
      height: 100vh;
      width: 100%;
    }
  
    div.profile-photo {
      height: 70vh;
      width: 70vh;
      border-radius: 35vh;
    }
  
    div.description {
      width: 40%;
      font-size: 19px;
      line-height: 1.4;
    }
  }
  
  @media (max-width: 800px) and (orientation: landscape) {
    div.profile-photo {
      height: 70vh;
      width: 70vh;
      border-radius: 35vh;
    }
  
    div.description {
      width: 40%;
      font-size: 19px;
      line-height: 1.4;
    }
  }
  @media (max-width: 700px) and (orientation: landscape) {
    div.profile-photo {
      height: 70vh;
      width: 70vh;
      border-radius: 35vh;
    }
  
    div.description {
      width: 40%;
      font-size: 18px;
      line-height: 1.4;
    }
  }
  @media (max-width: 600px) and (orientation: landscape) {
    div.profile-photo {
      height: 70vh;
      width: 70vh;
      border-radius: 35vh;
    }
  
    div.description {
      width: 40%;
      font-size: 16px;
      line-height: 1.4;
    }
  }