div.background-contact {
  width: 100%;
  height: 110vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

div.background-contact img {
  width: 100%;
  height: 100%;
  animation: sunset 30s infinite;
}

@keyframes sunset {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(35%) contrast(150%);
  }
  100% {
    filter: brightness(100%);
  }
}

@media (max-width: 1200px) and (orientation: portrait) {
  h2.specialty.contact {
    font-size: 80px;
    transform: translate(-200px, 5vh);
    word-wrap: break-word;
    /* width: 200px; */
    /* white-space: normal; */
  }

  div.background-contact {
    height: 100vh;
  }

  div.background-contact img {
    height: 100vh;
  }

  h1.name.contact {
    text-align: center;
    top: 20vh;
    width: 100%;
    left: 0vw;
    transform: none;
    animation: none;
    font-size: 150px;
  }

  h2.specialty.contact {
    font-size: 120px;
    white-space: normal;
    width: 100%;
    animation: none;
    text-align: center;
    top: 35vh;
    left: 0;
    transform: none;
  }
}

@media (max-width: 900px) and (orientation: portrait) {
  h1.name.contact {
    text-align: center;
    top: 20vh;
    width: 100%;
    left: 0vw;
    transform: none;
    animation: none;
  }

  h2.specialty.contact {
    font-size: 80px;
    white-space: normal;
    width: 100%;
    animation: none;
    text-align: center;
    top: 40vh;
  }
}

@media (max-width: 420px) and (orientation: portrait) {
  div.background-contact {
    height: 100vh;
  }
  h1.name.contact {
    font-size: 80px;
  }

  h2.specialty.contact {
    font-size: 60px;
    transform: translate(-10px, -5vh);
  }
}

@media (max-width: 376px) and (orientation: portrait) {
  h1.name.contact {
    font-size: 80px;
  }

  h2.specialty.contact {
    font-size: 60px;
    transform: translate(-10px, -5vh);
  }
}

@media (max-width: 359px) and (orientation: portrait) {
  h2.specialty {
    left: 15px;
  }

  h2.specialty.contact {
    transform: translate(0px, 0vh);
    font-size: 55px;
  }
}

@media (max-width: 1400px) and (orientation: landscape) {
  div.background-contact {
    height: 100vh;
  }

  div.background-contact img {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  h1.name.contact {
    transform: translate(0vw, 0vh);
  }
  h2.specialty.contact {
    font-size: 50px;
    transform: translate(-18vw, 0vh);
  }
}

@media (max-width: 800px) and (orientation: landscape) {
  h2.specialty.contact {
    font-size: 50px;
    transform: translate(-24vw, 0vh);
  }
}

@media (max-width: 700px) and (orientation: landscape) {
  h1.name.contact {
    transform: translate(0vw, 0vh);
  }
  h2.specialty.contact {
    font-size: 50px;
    transform: translate(-36vw, 0vh);
  }
}

@media (max-width: 640px) and (orientation: landscape) {
  h1.name.contact {
    font-size: 45px;
    transform: translate(0vw, 0vh);
  }
  h2.specialty.contact {
    font-size: 35px;
    transform: translate(-36vw, 0vh);
  }
}
